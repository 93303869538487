<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>New Dehesa</h1>
      <p>
        In Spain a Dehesa is a type of forest that is dominated by oak trees (Quercus ilex, Quercus suber). The Dehesa
        is man-made over centuries by using the forest and what it produces for many different agricultural activities
        including livestock grazing. The iberian pig is an integral part of the Dehesa as it feeds during autumn
        and winter on acorns from the oak trees.
      </p>
      <p>
        Unfortunately, in recent times undergrazing and overgrazing and other issues have led to <router-link
          :to="{ name: 'desertification' }">
          desertification</router-link> and what once was a highly productive agroforestry system is now in decline.
        What makes it worse is also the fact the very old oak trees are dying too as a consequence.
      </p>

      <h2>New Ways</h2>
      <p>
        We have come up with new ways to maintain the idea of the Dehesa and restore the fertility in the soil while
        also creating a system that is very productive.
      </p>
      <p>
        Instead of using the method of set stocking for livestock (a fixed amount of animals per hectare for a longer
        period) we use high stocking density (a lot of animals in a small space for a short amount of time) and a system
        of animal lanes and small sized areas that play different roles through the year.
      </p>

      <picture>
        <source media="(max-width: 500px)" srcset="/img/concept/animalCropPasture.png">
        <img src="/img/concept/animalCropPasture.png" />
      </picture>

      <p>
        Depending on their natural instincts the animals will uproot the soil (pigs) or forage and open up the surface
        with their hooves. They walk around free - only guided by animal lanes and gates - and go to a water hole the
        same way as wild animals do.
      </p>
      <p>
        After pigs have uprooted an area we seed a polyculture of crops in it so that there is new forage for bovines
        and pigs. Once the crop is ready for harvest the bovines eat the leaves and the pigs go for the root crop.
      </p>
      <p>
        This is just a quick overview of our concept. It is not <em>set in stone</em> and evolves as we learn from
        observation. Our main goal is to restore soil fertility in order to create abundance so that eventually we can
        harvest the surplus.
      </p>
      <p>
        In addition to the above we also add a lot of new plant species as crop or plant a lot of additional trees for
        shade and biomass and also as forage.
      </p>

    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
export default {
  name: "DehesaEN",
  components: {
    Sidebar
  },
};
</script>
